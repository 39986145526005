.backlog-simmer {
    background-color: #f5f8fa;
    background-image: -moz-linear-gradient(160deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.85) 60%, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0)), color-stop(60%, rgba(255, 255, 255, 0.85)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(160deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.85) 60%, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(160deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.85) 60%, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(160deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.85) 60%, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(160deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.85) 60%, rgba(255, 255, 255, 0) 100%);
    background-repeat: repeat-y;
    position: relative;
    animation: shimmer 1s linear infinite;
    width: 100%;
    /* height: 650px; */
}

@-moz-keyframes shimmer {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}

@-o-keyframes shimmer {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}

@-ms-keyframes shimmer {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}


.dark-backlog-simmer {
    background-color: #2b2b40;
    background-image: -moz-linear-gradient(160deg, #2b2b40 0%, #2b2b40 25%, #3a3a50 60%, #2b2b40 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, #2b2b40), color-stop(25%, #2b2b40), color-stop(60%, rgba(255, 255, 255, 0.85)), color-stop(100%, #2b2b40));
    background-image: -webkit-linear-gradient(160deg, #2b2b40 0%, #2b2b40 25%, #3a3a50 60%, #2b2b40 100%);
    background-image: -o-linear-gradient(160deg, #2b2b40 0%, #2b2b40 25%, #3a3a50 60%, #2b2b40 100%);
    background-image: -ms-linear-gradient(160deg, #2b2b40 0%, #2b2b40 25%, #3a3a50 60%, #2b2b40 100%);
    background-image: linear-gradient(160deg, #2b2b40 0%, #2b2b40 25%, #3a3a50 60%, #2b2b40 100%);
    background-repeat: repeat-y;
    position: relative;
    animation: shimmer 1s linear infinite;

}

@-moz-keyframes shimmer {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}

@-o-keyframes shimmer {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}

@-ms-keyframes shimmer {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}

.padding-box{
    padding-top:137px !important;
}

.h-500px{
    height: 500px !important;
}

